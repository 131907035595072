@charset "utf-8";

// section#is_modal.init_modal>.underlay+.inner>em#is_modalClose{Close}
.init_modal {
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  .underlay {
    background-color: rgba(#000, 0.5);
    height: 100%;
    width: 100%;
  }
  .inner {
    background-color: #fff;
    bottom: auto;
    left: 50%;
    max-height: 90%;
    max-width: 95%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 100px 50px;
    position: fixed;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    &::-webkit-scrollbar {
      height: 3px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555;
    }
  }
  @media (max-width: 576px) {
    .inner {
      padding: 50px 10px;
    }
  }
}
