@charset "utf-8";

@import 'aos/src/sass/aos.scss';

[data-aos='fade-up-right2'] {
  opacity: 0;
  transform: translate(-30px, 30px) rotate(1deg);
  transform-origin: 0 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translate(0, 0) rotate(0);
  }
}

[data-aos='fade-up-left2'] {
  opacity: 0;
  transform: translate(30px, 30px) rotate(-1deg);
  transform-origin: 0 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translate(0, 0) rotate(0);
  }
}
